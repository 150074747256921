.hover-underline-animation {
  position: relative;
  color: #000000;

  display: inline-block;
  flex-direction: column;
  display: flex;
}

.hover-underline-animation::after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);

  transform-origin: bottom left;
}

@media screen and (max-width: 901px) {
  .sidebar-mobile {
    text-align: center;
  }
  .sidebar-mobile-width {
    width: 100% !important;
  }
}
